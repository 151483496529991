import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import HOST_URL from './config';
import { useNavigate } from 'react-router-dom';

export default function Edit() {
    const navigate = useNavigate();
    const {id} =useParams();
    const [data,setData] = useState([]);
    const [menu,setMenu] = useState('breakfast')
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
        axios.get(HOST_URL + '/menuitems/list/' + id).then(res=>{
          setData(res.data)
          setLoading(false)
        }).catch(error=>{
          console.log(error);
        })
      },[])
      const hc = async() => {
        try {
        const cass = await axios.post(HOST_URL + '/menuitems/update/' + id,
            {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: (data),
              }
        )
        console.log('THE CASS',cass)
    } catch(error) {
        console.log(error)
    }

      }
      return loading ? (<div>loading</div>):(
        <div className="container">
            <div className="editwrap">
            <div className="editinputs">
                <div className="inputwrap">
                    <div className="inputname">name</div>
                    <input value={data.name} onChange={(e)=>{
                        setData({...data,name:e.target.value})
                    }}/>
                </div>
                <div className="inputwrap">
                    <div className="inputname">description</div>
                    <textarea value={data.description} onChange={(e)=>{
                        setData({...data,description:e.target.value})
                    }}/>
                </div>

                <div className="inputwrap">
                    <div className="inputname">price</div>
                    <input value={data.price} onChange={(e)=>{
                        setData({...data,price:e.target.value})
                    }}/>
                </div>
                <div className="inputwrap">
                    <div className="inputname">menu</div>
                     
                    <select value={data.menu} onChange={(e)=>{
                        setData({...data,menu:e.target.value})
                    }}>
                        <option value="breakfast">breakfast</option>
                        <option value="lunch">lunch</option>
                        <option value="dinner">dinner</option>
                        </select>
                </div>
                <div className="inputwrap">
                    <div className="inputname">sub menu</div>
                    <select value={data.menu2} onChange={(e)=>{
                        setData({...data,menu2:e.target.value})
                    }}>
                        <option value="madetoorder">made to order</option>
                        <option value="grabandgo">grab and go</option>
                        <option value="soup">soup</option>
                    </select>
                </div>

            </div>
            <div className="buttonwrap">
            <div className="button" style={{flex:1,textAlign:'center'}} onClick={()=>{navigate(-1)}}>go back</div>
            <div className="button" style={{flex:1,textAlign:'center'}} onClick={()=>{hc()}}>update</div>
            </div>
            <div className="buttonwrap">
            <div className="button" style={{flex:1,textAlign:'center',backgroundColor:'red',color:'white'}} onClick={()=>{
                if(window.confirm("do you really want to delete this item?")) {
                    console.log('Delete', data._id)
                    axios.post(HOST_URL + "/menuitems/destroy/" + data._id ).then(()=>{
                        console.log('DELETE SUCCESS')
                        navigate(-1);
                    }).catch(error=>{
                        console.log('error deleteing',error)
                    })
                
                } else {

                }
                }}>delete</div>
            </div>

        </div>
        </div>
    )
}
