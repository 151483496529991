import React, {useEffect,useState} from 'react';
import axios from 'axios';
import HOST_URL from './config';
import { useNavigate } from 'react-router-dom';

console.log('Current Host:', HOST_URL);


function Main(props) {
    const navigate = useNavigate();
const {socket} = props;
console.log('the sockets',socket)
    const [data,setData] = useState([]);
    const [menu,setMenu] = useState('breakfast')
    useEffect(()=>{
      axios.get(HOST_URL + '/menuitems/listall').then(res=>{
        console.log('DATA',res.data)
        setData({list:res.data.list,menu:res.data.menu})
      }).catch(error=>{
        console.log(error);
      })
    },[])
    return data.list ? (
        <div className="container">
                        <div className="toggle_menu">Menu is set to {data.menu}</div>

            <div className="menuselect">
                <select
                    style={{flex:1}}
                    onChange={(e)=>{
                        setMenu(e.target.value);

                    }}
                    value={menu}
                >
                    <option value="breakfast">breakfast</option>
                    <option value="lunch">lunch</option>
                    <option value="dinner">dinner</option>
                    <option value="closedmenu">closed w/ menu</option>
                    <option value="closedblank">closed & blank</option>
                </select>
                    <div className="button"
                        onClick={()=>{

                            if(window.confirm("Change the menu mode?")) {
                                console.log('the menu is',menu)
                                axios.post(HOST_URL + '/config/setmode',{screenmode:menu}).then(res=>{
                                    console.log('done',res.data);
                                    axios.get(HOST_URL + '/menuitems/listall').then(res=>{
                                        console.log('DATA',res.data)
                                        setData({list:res.data.list,menu:res.data.menu})
                                      }).catch(error=>{
                                        console.log(error);
                                      })
                                }).catch(error=>{
                                    console.log(error)
                                })
    
                            } else {
                            }


                        }}
                    >change menu</div>
                    </div>
                    <div className="adminmenubuttons">
                    <div className="button"
                        onClick={()=>{
                            if(window.confirm("force menu to reload?")) {
                                console.log('reloading')
                                axios.get(HOST_URL + '/menuitems/reload').then(res=>{
                                    console.log('done',res.data);
                                }).catch(error=>{
                                    console.log(error)
                                })
    
                            } else {

                            }
                        }}
                    >force refresh menu</div>
                    <div className="button"
                        onClick={()=>{
                            navigate('/create')
                        }}
                    >create new item</div>

            </div>
            <h1>breakfast</h1>
            <div style={{width:'100%'}}>
            {data.list.filter(e=>e.menu == "breakfast" && e.status == "active").map(d=>{
                return (
                    <div key={d._id} className="itemwrap" style={{
                        backgroundColor:d.status =='active' ? '#ebebeb' : '#303030',
                        width:'100%'
                    }}
                    >
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemname i">{d.name}</div>
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemdesc i double-width">{d.description}</div>
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemprice i">{d.price}</div>
                        <div style={{}} className="itembutton"
                                                onClick={()=>{
                                                    console.log('THE D',d._id)
                                                    navigate(`/item/${d._id}`);
                                                }}
                        >edit item</div>
                        <div style={{backgroundColor:d.soldout ? "red" : "green"}} className="itembutton"
                        
                            onClick={()=>{
                                console.log('THE D',d._id)
                                const jsondata = {
                                    id:d._id
                                }
                                axios.post(HOST_URL + '/menuitems/toggle',jsondata,{
                                    headers : {
                                        'Content-Type': 'application/json',
                                    }
                                }).then(res=>{
                                    console.log('toggles the res',res.data)
                                    axios.get(HOST_URL + '/menuitems/listall').then(res=>{
                                        console.log('DATA',res.data)
                                        setData({list:res.data.list,menu:res.data.menu})
                                    }).catch(error=>{
                                        console.log(error);
                                    })
                                }).catch(error=>{
                                    console.log('error',error)
                                })
                            }}

                        
                        >{d.soldout ? 'soldout' : 'available'}</div>
                        <div style={{}} className="itembutton archive" 

                            onClick={()=>{
                                console.log('THE D',d._id)
                                const jsondata = {
                                    id:d._id
                                }
                                axios.post(HOST_URL + '/menuitems/toggleactive',jsondata,{
                                    headers : {
                                        'Content-Type': 'application/json',
                                    }
                                }).then(res=>{
                                    console.log('toggles the res',res.data)
                                    axios.get(HOST_URL + '/menuitems/listall').then(res=>{
                                        console.log('DATA',res.data)
                                        setData({list:res.data.list,menu:res.data.menu})
                                    }).catch(error=>{
                                        console.log(error);
                                    })
                                }).catch(error=>{
                                    console.log('error',error)
                                })
                            }}

                        >{d.status}</div>
                    </div>
                )
            })}
            </div>
            <h1>lunch</h1>
            <div style={{width:'100%'}}>
            {data.list.filter(e=>e.menu == "lunch" && e.status == "active" && e.menu2 !="soup").map(d=>{
                return (
                    <div key={d._id} className="itemwrap" style={{
                        backgroundColor:d.status =='active' ? '#ebebeb' : '#303030',
                        width:'100%'
                    }}
                    >
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemname i">{d.name}</div>
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemdesc i double-width">{d.description}</div>
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemprice i">{d.price}</div>
                        <div style={{}} className="itembutton"
                                                onClick={()=>{
                                                    console.log('THE D',d._id)
                                                    navigate(`/item/${d._id}`);
                                                }}
                        >edit item</div>
                        <div style={{backgroundColor:d.soldout ? "red" : "green"}} className="itembutton"
                        
                            onClick={()=>{
                                console.log('THE D',d._id)
                                const jsondata = {
                                    id:d._id
                                }
                                axios.post(HOST_URL + '/menuitems/toggle',jsondata,{
                                    headers : {
                                        'Content-Type': 'application/json',
                                    }
                                }).then(res=>{
                                    console.log('toggles the res',res.data)
                                    axios.get(HOST_URL + '/menuitems/listall').then(res=>{
                                        console.log('DATA',res.data)
                                        setData({list:res.data.list,menu:res.data.menu})
                                    }).catch(error=>{
                                        console.log(error);
                                    })
                                }).catch(error=>{
                                    console.log('error',error)
                                })
                            }}

                        
                        >{d.soldout ? 'soldout' : 'available'}</div>
                        <div style={{}} className="itembutton archive" 

                            onClick={()=>{
                                console.log('THE D',d._id)
                                const jsondata = {
                                    id:d._id
                                }
                                axios.post(HOST_URL + '/menuitems/toggleactive',jsondata,{
                                    headers : {
                                        'Content-Type': 'application/json',
                                    }
                                }).then(res=>{
                                    console.log('toggles the res',res.data)
                                    axios.get(HOST_URL + '/menuitems/listall').then(res=>{
                                        console.log('DATA',res.data)
                                        setData({list:res.data.list,menu:res.data.menu})
                                    }).catch(error=>{
                                        console.log(error);
                                    })
                                }).catch(error=>{
                                    console.log('error',error)
                                })
                            }}

                        >{d.status}</div>
                    </div>
                )
            })}
            </div>            
            <h1>soups</h1>
            <div style={{width:'100%'}}>
            {data.list.filter(e=>e.menu == "lunch" && e.menu2 =="soup" && e.status == "active").map(d=>{
                return (
                    <div key={d._id} className="itemwrap" style={{
                        backgroundColor:d.status =='active' ? '#ebebeb' : '#303030',
                        width:'100%'
                    }}
                    >
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemname i">{d.name}</div>
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemdesc i double-width">{d.description}</div>
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemprice i">{d.price}</div>
                        <div style={{}} className="itembutton"
                                                onClick={()=>{
                                                    console.log('THE D',d._id)
                                                    navigate(`/item/${d._id}`);
                                                }}
                        >edit item</div>
                        <div style={{backgroundColor:d.soldout ? "red" : "green"}} className="itembutton"
                        
                            onClick={()=>{
                                console.log('THE D',d._id)
                                const jsondata = {
                                    id:d._id
                                }
                                axios.post(HOST_URL + '/menuitems/toggle',jsondata,{
                                    headers : {
                                        'Content-Type': 'application/json',
                                    }
                                }).then(res=>{
                                    console.log('toggles the res',res.data)
                                    axios.get(HOST_URL + '/menuitems/listall').then(res=>{
                                        console.log('DATA',res.data)
                                        setData({list:res.data.list,menu:res.data.menu})
                                    }).catch(error=>{
                                        console.log(error);
                                    })
                                }).catch(error=>{
                                    console.log('error',error)
                                })
                            }}

                        
                        >{d.soldout ? 'soldout' : 'available'}</div>
                        <div style={{backgroundColor:d.status == 'green'}} className="itembutton archive" 

                            onClick={()=>{
                                console.log('THE D',d._id)
                                const jsondata = {
                                    id:d._id
                                }
                                axios.post(HOST_URL + '/menuitems/toggleactive',jsondata,{
                                    headers : {
                                        'Content-Type': 'application/json',
                                    }
                                }).then(res=>{
                                    console.log('toggles the res',res.data)
                                    axios.get(HOST_URL + '/menuitems/listall').then(res=>{
                                        console.log('DATA',res.data)
                                        setData({list:res.data.list,menu:res.data.menu})
                                    }).catch(error=>{
                                        console.log(error);
                                    })
                                }).catch(error=>{
                                    console.log('error',error)
                                })
                            }}

                        >{d.status}</div>
                    </div>
                )
            })}
            </div>
            <h1>disabled</h1>
            <div style={{width:'100%'}}>
            {data.list.filter(e=>e.status == "disabled").map(d=>{
                return (
                    <div key={d._id} className="itemwrap" style={{
                        backgroundColor:d.status =='active' ? '#ebebeb' : '#303030',
                        width:'100%'
                    }}
                    >
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemname i">{d.name}</div>
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemdesc i double-width">{d.description}</div>
                        <div style={{color:d.status == "active" ? "black" : "white"}} className="itemprice i">{d.price}</div>
                        <div style={{}} className="itembutton"
                                                onClick={()=>{
                                                    console.log('THE D',d._id)
                                                    navigate(`/item/${d._id}`);
                                                }}
                        >edit item</div>
                        <div style={{backgroundColor:d.soldout ? "red" : "green"}} className="itembutton"
                        
                            onClick={()=>{
                                console.log('THE D',d._id)
                                const jsondata = {
                                    id:d._id
                                }
                                axios.post(HOST_URL + '/menuitems/toggle',jsondata,{
                                    headers : {
                                        'Content-Type': 'application/json',
                                    }
                                }).then(res=>{
                                    console.log('toggles the res',res.data)
                                    axios.get(HOST_URL + '/menuitems/listall').then(res=>{
                                        console.log('DATA',res.data)
                                        setData({list:res.data.list,menu:res.data.menu})
                                    }).catch(error=>{
                                        console.log(error);
                                    })
                                }).catch(error=>{
                                    console.log('error',error)
                                })
                            }}

                        
                        >{d.soldout ? 'soldout' : 'available'}</div>
                        <div style={{}} className="itembutton archive" 

                            onClick={()=>{
                                console.log('THE D',d._id)
                                const jsondata = {
                                    id:d._id
                                }
                                axios.post(HOST_URL + '/menuitems/toggleactive',jsondata,{
                                    headers : {
                                        'Content-Type': 'application/json',
                                    }
                                }).then(res=>{
                                    console.log('toggles the res',res.data)
                                    axios.get(HOST_URL + '/menuitems/listall').then(res=>{
                                        console.log('DATA',res.data)
                                        setData({list:res.data.list,menu:res.data.menu})
                                    }).catch(error=>{
                                        console.log(error);
                                    })
                                }).catch(error=>{
                                    console.log('error',error)
                                })
                            }}

                        >{d.status}</div>
                    </div>
                )
            })}
            </div>
        </div>
    ) : (<div>mnull</div>)
}

export default Main;

